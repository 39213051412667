'use-client'

import React, { useState, useEffect } from 'react';
import { Providers, Events } from '@dsi-arena/frontend-react-framework';
import { Event } from 'components/atoms/ticketFromUser';

const TicketsFromUser = () => {
    const { useTranslating, useServing, useAuth, useLoading } = Providers;
    const { Language } = useTranslating();
    const [tickets, setTickets] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { sessId } = useAuth();
    const { setIsLoading } = useLoading();
    const { useEventListener } = Events;
    const { useGetTicketsFromUser } = useServing();
    const getTicketsFromUser = useGetTicketsFromUser();

    const getTickets = async () => {
        if (!sessId) {
            setTickets([]);
            return;
        }

        try {
            setIsLoading(true);
            const response = await getTicketsFromUser.getTicketsFromUser();
            setIsLoading(false);

            const ticketsData = {};
            const output = [];
            for (let i = 0; i < response.data.length; i++) {
                const ticket = response.data[i];
                if (!ticketsData[ticket.evento_id]) {
                    ticketsData[ticket.evento_id] = {};
                    ticketsData[ticket.evento_id].event_id = ticket.evento_id;
                    ticketsData[ticket.evento_id].title = ticket.evento;
                    ticketsData[ticket.evento_id].date = ticket.data_evento;
                    ticketsData[ticket.evento_id].time = ticket.hora_evento;
                    ticketsData[ticket.evento_id].tickets = [];
                }

                ticketsData[ticket.evento_id].tickets.push(ticket);
            }

            for (let key in ticketsData) {
                output.push(ticketsData[key]);
            }

            setTickets(output);

        } catch (error) {
            console.error("Error getting tickets from user:", error);
        }
    }

    useEffect(() => {
        getTickets();
    }, [sessId]);

    useEventListener('user.tickets.get', getTickets);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredTickets = tickets.filter(ticket => {
        return ticket.title.toLowerCase().includes(searchQuery.toLowerCase())
    });

    return (
        <div>
            <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-4"
                type="text"
                placeholder={Language.getTranslation("Search")}
                value={searchQuery}
                onChange={handleSearch}
            />
            {filteredTickets.map(ticket => {
                return (
                    <Event
                        key={ticket.event_id} // Assuming title is unique, otherwise use an appropriate key
                        eventName={ticket.title}
                        eventDate={ticket.date}
                        eventTime={ticket.time}
                        tickets={ticket.tickets}
                    />
                )
            })}
        </div>
    );
}

export default TicketsFromUser;
