'use-client'

import React from 'react';
import { Events, Providers, Models } from '@dsi-arena/frontend-react-framework';
import TicketsFromUser from './ticketsFromUser';

export const GetExtraTabs = () => {
    const { Emit } = Events;
    const { Tab } = Models;
    const { useTranslating } = Providers;
    const { Language } = useTranslating();
    return [
        new Tab(Language.getTranslation("MyTickets"), <TicketsFromUser />, () => Emit('user.tickets.get')),
    ];
};